import {Component, OnInit, Input, Inject, Injector, OnDestroy, ViewEncapsulation} from '@angular/core';
import { StepperService } from 'src/app/stepper.service';

import { SeaComponent } from '../sea.component';
import { BaseFormComponentComponent } from 'src/app/shared/base-form-component/base-form-component.component';
import { SeaModel } from 'src/app/models/sea/sea-model';
import { SeaSearchService } from 'src/app/services/sea/sea-search.service';
import { MarketSeaProductsModel } from 'src/app/models/sea/market-sea-products.model';
import {
    SeaPriceDetailDialogComponent
} from "../../../components/sea-price-detail-dialog/sea-price-detail-dialog.component";
import {DynamicDialogRef} from "primeng/dynamicdialog";
import {
    TariffNotFoundDialogComponent
} from "../../../components/tariff-not-found-dialog/tariff-not-found-dialog.component";
import { AlternativeSegment } from 'src/app/models/alternative-segment';
import EntityUtil from 'src/app/shared/entity.util';
import { SseService } from 'src/app/services/sse-service';
import { SeaCalculatedFreightModel } from 'src/app/models/sea/sea-calculated-freight.model';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-price-request',
  templateUrl: './price-request.component.html',
  styleUrls: ['./price-request.component.scss']
})
export class SeaPriceRequestComponent extends BaseFormComponentComponent implements OnInit, OnDestroy {

  @Input("model") model: SeaModel;
  adminDialogRef: DynamicDialogRef;

  requestComponent: SeaPriceRequestComponent = this;
  showAlternativeSegment: boolean = true;
  alternativeSegmentStartIndex: number = null;


  private messageSubscription: Subscription | null = null;


  viewType:string = 'calendar';
  constructor(
    protected injector: Injector, @Inject(SeaComponent) public parent: SeaComponent, private stepperService: StepperService, private seaSearchService: SeaSearchService, private sseService: SseService) {
    super(injector)
    this.parent.isLoading = true;

  }



  ngOnInit(): void {
      this.parent.isConfirmation = false;
      if(this.model.selectedProduct && this.parent.showOnlyComponent) {
      this.model.selectedProduct.isSelectedFromList = true;
      if (this.model?.products){
          this.model.products = [this.model.selectedProduct].concat(this.model.products).filter(product => {
              return (!product?.isOverLimit && !product?.freights?.errorCode) || product?.isSelectedFromList;
          });
          this.model.products = this.model.products.filter((x, i, a) => a.indexOf(x) == i);
      }else {
          this.model.products = [];
          this.model.products = [this.model.selectedProduct].concat(this.model.products).filter(product => {
              return (!product?.isOverLimit && !product?.freights?.errorCode) || product?.isSelectedFromList;
          });
          this.model.products = this.model.products.filter((x, i, a) => a.indexOf(x) == i);
      }
    }

    this.parent.stepper.notShowReservation = this.onlyShowPriceRequests();

    if (!this.messageSubscription) { 
      this.messageSubscription =   this.sseService.getMessages().subscribe(message => {
        let price = JSON.parse(message) as SeaCalculatedFreightModel;
        let product = this.model.products.find(f => f.uuid == price.uuid);
        if(product) { 
          product.freights = price;
          product.isCompleteOnlinePrice = true;
          if(price.grandTotal) {
            product.hasOnlinePrice = true;
          } else {
            
            let indx = this.model.products.findIndex(f => f.uuid == price.uuid);
            if(indx > -1)  { 
              this.model.products.splice(indx, 1);
              this.sseService.onChangeProduct.next(null);      
            }
          }
        }
  
      })
    }
    
   }

   async makeReservation(selectedProduct: MarketSeaProductsModel = null, marketProductsDefId = null, productId = null) {

    this.isLoading = true;
  
    if(selectedProduct) this.model.marketProductsDefId = selectedProduct.marketProductsDefId;
    if(!selectedProduct) this.model.marketProductsDefId = marketProductsDefId;
    this.model.selectedProduct = selectedProduct;

    if (!selectedProduct && marketProductsDefId){
        this.model.newPriceProduct = productId;
    }

    try {
      if(!this.model.selectedProduct ||  !this.model.selectedProduct.isSelectedFromList) {
       this.model = await this.seaSearchService.offer(this.model).toPromise();
      } else {
        this.model = await  this.seaSearchService.insurance(this.model).toPromise();
      }

        this.isLoading = false;
        this.parent.reservationInfoLoading = true;
        window.scroll({top: this.parent.initialOffsetTop, left: 0, behavior: 'smooth'});
        this.parent.container.clear();
        this.parent.model = this.model;

        this.stepperService.changeCurrentStep(3);
        this.parent.renderStepComponent(this.parent.stepComponents[3], this.model, 3);

        if(!this.parent.showOnlyComponent) {
          this.addQueryParam("page", "REZ")
        }

    } catch(error) {
        this.model.selectedProduct = null;
        // zaman aşımına uğrayan bir istek gerçekleşti ise bir önceki adıma geri dönüyoruz.
        if (error == 'searchTimeOut') {
            this.parent.container.clear();
            this.stepperService.changeCurrentStep(1);
            window.scroll({ top: this.parent.initialOffsetTop, left: 0, behavior: 'smooth' });
        }

        this.isLoading = false;
        this.parent.reservationInfoLoading = false;
        this.parent.isLoading = false;
    }


  }

    async priceInfo(product: MarketSeaProductsModel) {
        this.adminDialogRef = this.dialogService.open(SeaPriceDetailDialogComponent, { header: null, showHeader: false, modal: true, width: '800px', height: 'auto', closable: false, data: { product: product, model: this.model, parentComponent: this } });
    }

    ngOnDestroy() {
        if (this.adminDialogRef) {
            this.adminDialogRef.close();
        }
        this.unsubscribeFromMessages();
    }

    private unsubscribeFromMessages(): void {
      if (this.messageSubscription) {
        this.messageSubscription.unsubscribe();
        this.messageSubscription = null;
      }
    }

    calculateTeu() {

       return  this.model?.containerType?.name.startsWith('2') ? this.model?.containerCount : this.model?.containerCount * 2;
    }

    isPrice(){
      return document.getElementById('price_newprice_btn') !== null;
    }

    frontTariffNotFound(){
        this.adminDialogRef = this.dialogService.open(TariffNotFoundDialogComponent, { header: null, showHeader: false, modal: true, width: '800px', height: 'auto', closable: false, data: {type: "front"}});
    }
    endTariffNotFound(){
        this.adminDialogRef = this.dialogService.open(TariffNotFoundDialogComponent, {  header: null,  showHeader: false, modal: true, width: '800px', height: 'auto', closable: false, data: {type: "end"}});
    }
    frontEndTariffNotFound(){
        this.adminDialogRef = this.dialogService.open(TariffNotFoundDialogComponent, {  header: null,  showHeader: false, modal: true, width: '800px', height: 'auto', closable: false, data: {type: "frontend"}});
    }
    onlyShowPriceRequests() {
        if(this.model.isNoPrice) return false;
        if(!this.model.products) return true;


        const noPriceProductsSize = this.model.products.filter(product => ((product?.isOverLimit && !product?.isSelectedFromList) || product?.freights?.errorCode))

        if(noPriceProductsSize.length == this.model.products.length) return true;

        return false;

      }
      onSelectViewType(viewType:string) {
        this.viewType = viewType;
      }


      async onShowAlternative() {

        this.isLoading = true;
        let clonedModal = Object.assign({}, this.model);
        let fromPortAlternativeSegments: AlternativeSegment[] = [];
        let toPortAlternativeSegments: AlternativeSegment[] = [];

        this.alternativeSegmentStartIndex = this.model.products.length -1

        let alternativeProducts: MarketSeaProductsModel[] = [];

        this.model.alternativeSegments.forEach(f => {
          if((f.toType == 'NFO' || f.toType == 'NFO') && !this.hasPriceProduct()) {
            if(f.fromOrTo == 'to' && f.toSeaPort) toPortAlternativeSegments.push(f);
            if(f.fromOrTo == 'from' && f.fromSeaPort) fromPortAlternativeSegments.push(f);
          }

          if(f.toType == 'ALL' || f.fromType == 'ALL') {
            if(f.fromOrTo == 'to' && f.toSeaPort) toPortAlternativeSegments.push(f);
            if(f.fromOrTo == 'from' && f.fromSeaPort) fromPortAlternativeSegments.push(f);
          }
        })

        for (let id = 0; id < fromPortAlternativeSegments.length; id++) {
          const fp = fromPortAlternativeSegments[id];
          const segment = await this.seaSearchService.segment(fp.fromSeaPort.id, this.model.arrivalPort.id, this.model.departureCountry.id, this.model.arrivalCountry.id).toPromise();
          if(segment) {
            clonedModal.segment = segment;
            clonedModal.products = [];
            clonedModal.departurePort = EntityUtil.toMinDTO(fp.fromSeaPort);
            const resp = await this.seaSearchService.search(clonedModal).toPromise();

            resp.products.map(s => {
              s.isAlternativeSegment = true; s.alternativeSegmentPortName = `${fp.portName} - ${this.model.arrivalPort?.name}`;
              s.description = fp.description;
              s.descriptionEn = fp.descriptionEn;
              s.alternativeArrivalPort = resp.arrivalPort?.name;
              s.alternativeDeparturePort = resp.departurePort?.name;
            });
            const hasPriceProducts = resp.products.filter(p => !p.freights.errorCode && p.freights.grandTotal);
            alternativeProducts = alternativeProducts.concat(hasPriceProducts);
          }
        }
        
             
        for (let id = 0; id < toPortAlternativeSegments.length; id++) {
          const tp = toPortAlternativeSegments[id];
          const segment = await this.seaSearchService.segment(this.model.departurePort.id, tp.toSeaPort.id, this.model.departureCountry.id, this.model.arrivalCountry.id).toPromise();
          if(segment) {
            clonedModal.segment = segment;
            clonedModal.products = [];
            clonedModal.arrivalPort = EntityUtil.toMinDTO(tp.toSeaPort);
            const resp = await this.seaSearchService.search(clonedModal).toPromise();

            resp.products.map(s => {
              s.isAlternativeSegment = true; s.alternativeSegmentPortName = `${this.model.departurePort?.name} - ${tp.portName}`;
              s.description = tp.description; s.descriptionEn = tp.descriptionEn;
              s.alternativeArrivalPort = resp.arrivalPort?.name;
              s.alternativeDeparturePort = resp.departurePort?.name;
            });
            const hasPriceProducts = resp.products.filter(p => !p.freights.errorCode && p.freights.grandTotal);
            alternativeProducts = alternativeProducts.concat(hasPriceProducts);
          }
        }

        if(!alternativeProducts || (alternativeProducts && alternativeProducts.length == 0)) {
          this.showMessage('warn', 'noProductAlternativeSegment');

        } else {
          this.model.products = this.model.products.concat(alternativeProducts);
        }

        
       

        this.isLoading = false;
        this.showAlternativeSegment = false;

      
        
      }

      public hasPriceProduct() {
        if(!this.model.products) return false;
        if(this.model.products.length == 0) return false;
         return this.model.products.find(s => s?.freights.grandTotal && !s?.freights.errorCode)  ? true : false;
     }
      
}
