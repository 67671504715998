import { AfterViewInit, Component, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { SeaModel } from 'src/app/models/sea/sea-model';
import { EventEmitter } from '@angular/core';
import { MarketSeaProductsModel } from 'src/app/models/sea/market-sea-products.model';
import { TranslateService } from '@ngx-translate/core';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { SeaCardModalComponent } from '../card-modal/sea-card-modal.component';
import { SeaPriceRequestComponent } from 'src/app/pages/sea/price-request/price-request.component';
import { SseService } from 'src/app/services/sse-service';
import { Subscription } from 'rxjs';

export interface ReservationData {
  selectedProduct: MarketSeaProductsModel | null;
  marketProductsDefId: number | null;
  productId: number | null;
}


interface DateInfo {
  day: number;
  current: boolean;
  event: boolean;
  label?: string;
  price?: number;
  product?: MarketSeaProductsModel;
  count?: number;
}

interface Month {
  name: string;
  year: number;
  dates: DateInfo[];
}



@Component({
  selector: 'app-sea-calendar',
  templateUrl: './sea-calendar.component.html',
  styleUrls: ['./sea-calendar.component.scss'],
})


export class SeaCalendarComponent implements AfterViewInit, OnInit, OnDestroy {

  cardModalRef: DynamicDialogRef;
  responsiveOptions = [{breakpoint: '1024px',numVisible: 3,numScroll: 3},{breakpoint: '768px',numVisible: 2,numScroll: 2},{breakpoint: '560px',numVisible: 1,numScroll: 1}]
  etds: Date[] = [];
  // Haftalık gün başlıkları
  days = ['Pzt', 'Sal', 'Çar', 'Per', 'Cum', 'Cmt', 'Paz'];
  private productChangeSubscribe: Subscription | null = null;
  // Ay bilgileri
  calendarMonths: Month[]  =[];
  allProducts: MarketSeaProductsModel[]  =[];

  @Input() model: SeaModel = null;
  @Input() loading: boolean = false;
  @Output() makeReservation: EventEmitter<ReservationData> = new EventEmitter<ReservationData>();
  @Output() priceInfo: EventEmitter<MarketSeaProductsModel> = new EventEmitter<MarketSeaProductsModel>();
  @Input() requestComponent: SeaPriceRequestComponent = null;




  constructor(public translation: TranslateService, private dialogService: DialogService, private sseService: SseService) {

  }

  ngOnInit(): void {
       this.allProducts = this.model?.products;
       // Takvim tarihleri ve dataları initalize ediliyor.
       this.initCalender();


       // Ürün silindiğinde takvimi yenile.
        if (!this.productChangeSubscribe) { 
             this.productChangeSubscribe =   this.sseService.getOnChangeProduct().subscribe(product => {
               this.initCalender();         
             })
        }
       
  }


  ngOnDestroy(): void {
    if (this.productChangeSubscribe) {
      this.productChangeSubscribe.unsubscribe();
      this.productChangeSubscribe = null;
    }
  }

  ngAfterViewInit(): void {
  }

  initCalender() {

      this.calendarMonths = [];
      
       // Takvim tarihleri ve dataları initalize ediliyor.
       if(this.model && this.model.products) {

        // Etd tarihleri üründen elde edilir.
        this.etds = this.getEtds(this.model.products);
        
        // önümüzdeki 4 ay tespit edilir.
        const nextMonths: any[] = this.getNextFourMonths();
    
        nextMonths.forEach(date => {
          this.calendarMonths.push({
            name: this.getMonthName(date), 
            year: this.getYear(date), 
            dates:this.generateMonth(this.getMonthNumeric(date), this.getYear(date), this.getProductsForCalendar(this.model.products.filter(f => f.freights && f.freights.etd), this.getMonthNumeric(date), this.getYear(date)))
         });
        })
        console.log(this.calendarMonths, "calendarMonths")
      }
  }

  getNextFourMonths() {
    let currentDate = new Date();
    let months = [];
  
    // Bulunduğumuz ay ve sonraki 4 ay
    for (let i = 0; i < 4; i++) {
      let date = new Date(currentDate.getFullYear(), currentDate.getMonth() + i, 1);
      months.push(date);
    }
  
    return months;
  }


  getProductsForCalendar(products: MarketSeaProductsModel[], month: number, year:number): any[] {


    return products.filter(f => this.getMonthNumeric(new Date(f.freights.etd)) ==  month && f.freights.etd && this.getYear(new Date(f.freights.etd)) == year).map(p => {
      return {
        day: new Date(p.freights.etd).getDate(),
        label: 'ETD',
        price: p.freights.grandTotal,
        current: false,
        event: true,
        product: p,
        count: this.getSameEtdProductSize(p)
      } as DateInfo

    })
    
    
  }


  getMonthName(date: Date) {
     return date.toLocaleString('tr-TR', { month: 'long' });
  }

  getMonthNumeric(date: Date) {
    return date.getMonth() + 1;
  }
  getYear(date: Date) {
    return date.getFullYear();
  }

  getEtds(products: MarketSeaProductsModel[]): Date[] {
   return products.filter(q => q.freights && q.freights.etd).map(s => new Date(s.freights.etd));
  }

  getMinDate(dates: Date[]): Date {
   return new Date(Math.min(...dates.map(date => date.getTime())));
  }
  
  getMaxDate(dates: Date[]): Date {
    return new Date(Math.max(...dates.map(date => date.getTime())));
  }
  
  generateMonth(
    month: number,
    year: number,
    events: { day: number; label: string; price: number; priceChange?: boolean, product?: MarketSeaProductsModel }[]
  ): DateInfo[] {
    const dates: DateInfo[] = [];
    const firstDay = new Date(year, month - 1, 1).getDay();
    const daysInMonth = new Date(year, month, 0).getDate();

    // Ayın başlangıç gününe kadar boş kutular ekleyelim
    for (let i = 0; i < (firstDay + 6) % 7; i++) {
      dates.push({ day: 0, current: false, event: false });
    }

    // Ay içindeki günleri ekleyelim
    for (let i = 1; i <= daysInMonth; i++) {
      const event = events.find((e) => e.day === i);

      dates.push({
        day: i,
        current: true,
        event: !!event,
        label: event?.label,
        price: event?.price,
        product: event?.product,
        count: this.getSameEtdProductSize(event?.product)
      });


    }

    // Takvim düzenini tamamlamak için ay sonundaki boş kutuları ekleyelim
    const remaining = (42 - dates.length) % 7;
    for (let i = 0; i < remaining; i++) {
      dates.push({ day: 0, current: false, event: false });
    }

    return dates;
  }

  getSameEtdProductSize(product: MarketSeaProductsModel) {
    
    if((!this.allProducts || !product) || (product && !product?.freights?.etd)) return 0;

    const size =  this.allProducts.filter(w => w?.freights && w.freights?.etd).filter(p => {
        const etdProduct = new Date(p.freights.etd).toISOString().split('T')[0];
        const etdFilter = new Date(product.freights.etd).toISOString().split('T')[0];
        return etdProduct === etdFilter;
    }).length;
  
    return size > 1 ? size : 0;
  }
  async showCardProduct(product: MarketSeaProductsModel) {
    if(!this.model || !this.model?.products || !product) return;

    const products = this.model.products.filter(p => p.freights.etd == product.freights.etd);
    
    this.cardModalRef = this.dialogService.open(SeaCardModalComponent, { header: null, showHeader: false, styleClass:'pt-90', modal: true, width: '1000px', height: 'auto', closable: false, data: { products: products, model: this.model,  allProducts: this.model.products, requestComponent: this.requestComponent } });

    this.cardModalRef.onClose.subscribe((result) => {
      if (result) {
        this.model.products = result;
      }
    });


  }

  _makeReservation(selectedProduct: MarketSeaProductsModel = null, marketProductsDefId = null, productId = null) {
    this.makeReservation.emit({selectedProduct,marketProductsDefId,productId})
  }
  _priceInfo(product: MarketSeaProductsModel) {
    this.priceInfo.emit(product)
  }
}
