import { Component, OnInit,OnDestroy, ViewEncapsulation, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../services/user/authentication.service';
import { LoginDto } from '../models/auth/login-dto.model';
import { BaseFormComponentComponent } from '../shared/base-form-component/base-form-component.component';
import { AdminDialogComponent } from '../components/admin-dialog/admin-dialog.component';
import {  DynamicDialogRef } from 'primeng/dynamicdialog';
import { ChangeLanguageService } from '../services/change-language.service';
import { ForgotPasswordDialogComponent } from '../components/forgot-password-dialog/forgot-password-dialog.component';
import { UserService } from '../services/user/user.service';
import { ActivationDialogComponent } from '../components/activation-dialog/activation-dialog.component';
import { SseService } from '../services/sse-service';
@Component({
    selector: 'app-login',
    templateUrl: './app.login.component.html',
    styleUrls: ['./app.login.component.css'],
    encapsulation: ViewEncapsulation.None

})
export class AppLoginComponent extends BaseFormComponentComponent implements OnInit, OnDestroy  {
    isDropdownOpen: boolean = false;

    loginDTO: LoginDto = new LoginDto();
    displayBasic: boolean;
    adminDialogRef: DynamicDialogRef;
    hide: boolean = true;
    forgotDialogRef: DynamicDialogRef;
    activationDialogRef: DynamicDialogRef;

    constructor(protected injector: Injector, private authService: AuthenticationService, private userService: UserService, public router: Router, private languageService: ChangeLanguageService, private sseService: SseService) {
        super(injector);
    }



    ngOnInit() {
        if(localStorage.getItem('token')) {
            Object.keys(localStorage).filter(k => k != 'rememberMe' && k != 'lang' && k != 'uid' && k != 'efl-saved-consent' && k != 'efl-uuid').forEach(key => localStorage.removeItem(key))
        }
    }

    ngOnDestroy() {
        if (this.adminDialogRef) {
            this.adminDialogRef.close();
        }
    }
    toggleDropdown() {
        this.isDropdownOpen = !this.isDropdownOpen;
      }

    changeLanguage(lang: string) {
        this.translation.use(lang);
        localStorage.setItem('lang', lang);
        this.languageService.languageChanged.next(lang);
        this.toggleDropdown();
    }

    goToDashboard() {
        this.router.navigate(['home']);
    }

    async showAdminAuthDialog() {
        const header = await this.translation.get('continueAsAdmin').toPromise();
        this.adminDialogRef = this.dialogService.open(AdminDialogComponent, { header: header, width: '600px', height: 'auto', closable: false,  data: {loginDTO: this.loginDTO}});
    }

    async authenticate() {

        // this.showActivationConfirmDialog();
        // return;
        if(!this.loginDTO.email || !this.loginDTO.password) {
            this.showMessage('warn', 'enterEmailAndPass');
            return;
        }

        const valid = await this.isValid();

        if (!valid) return;

        this.isLoading = true;
        localStorage.removeItem('token');
        localStorage.removeItem('sessionId');

        try {
            const auth = await this.authService.login(this.loginDTO.email, encodeURIComponent(this.loginDTO.password)).toPromise();

            let user = JSON.stringify(auth.user);

            
            this.sseService.connect(auth);



            let token = auth.token;
            let sessionId = auth.sessionId;

            localStorage.setItem("token", token);
            localStorage.setItem("sessionId", sessionId);
            localStorage.setItem('user', JSON.stringify(user));

            const localeBySelectedLanguage = localStorage.getItem('lang') && localStorage.getItem('lang') == 'en' ? 'en-US' : 'tr-TR';
            await this.userService.setLocale(localeBySelectedLanguage).toPromise();

            if(JSON.stringify(user)['locale']) {
                this.userLocale = JSON.stringify(user)['locale'];
            }
            localStorage.setItem('locale', this.userLocale);



            if(auth.user.status == 'NW'){
                this.showActivationConfirmDialog();
                this.isLoading = false;
                return;
            }

            if(!auth.isManager) {
                this.router.navigate(['/home']).then(r => {
                    if(auth.existUserAuth) {
                        this.translation.get('userExistAuth').toPromise().then(msg => {
                            setTimeout(() => this.showMessage('warn',  `${msg} <br/> <b>IP:</b> ${auth.existUserAuth.ip} - ${new Date(auth.existUserAuth.date).toLocaleDateString()}`), 1000)
                        })
                    }
                })
                return;
            }
            this.isLoading = false;
            await this.showAdminAuthDialog();


        } catch(e) {
            
            if (e?.error?.message == 'failCountError'){
                this.showForgotDialog();
            }
            this.isLoading = false
        }


    }


    async showForgotDialog() {
        this.showAdminAuthDialog
        this.forgotDialogRef = this.dialogService.open(ForgotPasswordDialogComponent, { header: null, showHeader: false, modal:true,  width: '500px', height: 'auto', closable: false});
    }

    showActivationConfirmDialog() {
        this.forgotDialogRef = this.dialogService.open(ActivationDialogComponent, { header: null, showHeader: false, modal:true,  width: '500px', height: '500px', closable: false });
    }

}
