import { Injectable } from '@angular/core';
import { BaseService } from '../base.service';
import { HttpClient } from '@angular/common/http';
import {Observable} from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { MinDTO } from 'src/app/models/min-dto';
import { SeaModel } from 'src/app/models/sea/sea-model';
import { MarketSeaProductsModel } from 'src/app/models/sea/market-sea-products.model';

@Injectable({
  providedIn: 'root'
})
export class SeaSearchService  extends BaseService<any>{

  constructor(public httpClient: HttpClient) {
      super(
          'sea',
          httpClient);
  }

  search(seaModel: SeaModel): Observable<SeaModel> {
    let uri = new URL(environment.baseUrl + `/sea/search`);

    return this.httpClient.post<SeaModel>(uri.toString(), seaModel, { withCredentials: false, headers: this.httpHeaders() })
    .pipe(
      tap(),
      catchError(this.handleError)
    )
  }

  // get(marketOfferRequestsId: number): Observable<AirModel> {
  //   let uri = new URL(environment.baseUrl + `/air`).updateQueryParam({ id: marketOfferRequestsId });

  //   return this.httpClient.get<AirModel>(uri.toString(), { withCredentials: false, headers: this.httpHeaders() })
  //   .pipe(
  //     tap(),
  //     catchError(this.handleError)
  //   )
  // }

  segment(fromPortId: number, toPortId: number, fromCountryId: number, toCountryId: number): Observable<MinDTO> {
    let uri = new URL(environment.baseUrl + `/sea/segment`).updateQueryParam({ fromPortId, toPortId, fromCountryId, toCountryId });

    return this.httpClient.get<MinDTO>(uri.toString(), { withCredentials: false, headers: this.httpHeaders() })
    .pipe(
      tap(),
      catchError(this.handleError)
    )
  }

  discount(segmentId: number): Observable<string[]> {
    let uri = new URL(environment.baseUrl + `/sea/discount/${segmentId}`);
    return this.httpClient.get<string[]>(uri.toString(), { withCredentials: false, headers: this.httpHeaders() })
    .pipe(
      tap(),
      catchError(this.handleError)
    )
  }

  offer(seaModel): Observable<SeaModel> {
    let uri = new URL(environment.baseUrl + `/sea/offer`)

    return this.httpClient.post<SeaModel>(uri.toString(), seaModel, { withCredentials: false, headers: this.httpHeaders() })
    .pipe(
      tap(),
      catchError(this.handleError)
    )
  }

      
  due(product: MarketSeaProductsModel, due: number, ref: string, id: number): Observable<MarketSeaProductsModel> {
    let uri = new URL(environment.baseUrl + `/sea/due`).updateQueryParam({ due, ref, id });
    
    return this.httpClient.post<MarketSeaProductsModel>(uri.toString(), product, { withCredentials: false, headers: this.httpHeaders() })
    .pipe(
      tap(),
      catchError(this.handleError)
    )
  }

    get(marketOfferRequestsId: number): Observable<SeaModel> {
        let uri = new URL(environment.baseUrl + `/sea`).updateQueryParam({ id: marketOfferRequestsId });

        return this.httpClient.get<SeaModel>(uri.toString(), { withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            );
    }

    shipment(airModel): Observable<SeaModel> {
        let uri = new URL(environment.baseUrl + `/sea/shipment`);

        return this.httpClient.post<SeaModel>(uri.toString(), airModel, { withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }

    insurance(seaModel): Observable<SeaModel> {
        let uri = new URL(environment.baseUrl + `/sea/insurance`);

        return this.httpClient.post<SeaModel>(uri.toString(), seaModel, { withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }

  // shipment(airModel): Observable<AirModel> {
  //   let uri = new URL(environment.baseUrl + `/air/shipment`);

  //   return this.httpClient.post<AirModel>(uri.toString(), airModel, { withCredentials: false, headers: this.httpHeaders() })
  //   .pipe(
  //     tap(),
  //     catchError(this.handleError)
  //   )
  // }
  // insurance(airModel): Observable<AirModel> {
  //   let uri = new URL(environment.baseUrl + `/air/insurance`);

  //   return this.httpClient.post<AirModel>(uri.toString(), airModel, { withCredentials: false, headers: this.httpHeaders() })
  //   .pipe(
  //     tap(),
  //     catchError(this.handleError)
  //   )
  // }


}
