import { Component, Injector, OnInit, Renderer2, ViewEncapsulation } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AnimationOptions } from 'ngx-lottie';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { TrackingService } from 'src/app/services/tracking/tracking.service';
import { UserService } from 'src/app/services/user/user.service';
import { BaseFormComponentComponent } from 'src/app/shared/base-form-component/base-form-component.component';

@Component({
  selector: 'app-tracking',
  templateUrl: './tracking.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./tracking.component.scss']
})

export class TrackingeComponent extends BaseFormComponentComponent implements OnInit {

  container: string;
  shipmentNo: string;
  operation: string;
  loading: boolean = true;
  processLoading: boolean = false;
  showDialog: boolean = false;
  trackingData: any = null;
  fileUrl: string;
  safeUrl: SafeUrl = null;
  notResult: boolean = false;
  selectedInvoice = null;
  currencyOptions = [{label: 'USD', value: 'amount1'}, {label: 'EUR', value: 'amount2'}];
  selectedCurrency = "amount1"

  loaderOptions: AnimationOptions = {path: '/assets/lottie/tracking-load.json'};
  laoderStyles: Partial<CSSStyleDeclaration> = {width: '100%',margin: '0 auto',};

  constructor(protected injector: Injector, public config: DynamicDialogConfig, private renderer: Renderer2, private router: Router, private trackingService: TrackingService, private sanitizer: DomSanitizer, private userService: UserService) {
    super(injector)
  }


  async ngOnInit() {
    this.container = this.config.data.searchText || null;
    this.shipmentNo = this.config.data.shipmentNo || null;
    this.operation = this.config.data.operation.code.toLowerCase() || null;
    this.getTrackingData();
  }

  
  async getTrackingData() {
    this.loading = true;
    if(this.container || this.shipmentNo) {
      try {

        if(this.shipmentNo) this.trackingData = await this.trackingService.trackingShipment(this.shipmentNo, this.operation).toPromise();
        if(this.container) this.trackingData = await this.trackingService.tracking(this.container, this.operation).toPromise();
        
        
        this.notResult = Object.keys(this.trackingData).length == 0;
        this.loading = false;
      } catch {
        this.loading = false;
        this.notResult = true;
      }
     
    }
  }

  async onViewFile(document: any) {
    this.processLoading = true;
    try {
      const file = await this.userService.getDocByteForTrackAndTracking(document.reference, document.contentType, document.name).toPromise();
      this.fileUrl = URL.createObjectURL(file);
      this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.fileUrl);
      this.showDialog = true;
      this.processLoading = false;
    } catch {
      this.processLoading = false;
    }
  
  }

  getFileExtension(mimeType) {
    const mimeTypes = {
      'application/pdf': 'pdf',
      'application/msword': 'doc',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'docx',
      'application/vnd.ms-excel': 'xls',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx',
      'application/vnd.ms-powerpoint': 'ppt',
      'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'pptx',
      'image/jpeg': 'jpg',
      'image/png': 'png',
      'image/gif': 'gif',
      'image/bmp': 'bmp',
      'image/webp': 'webp',
      'text/plain': 'txt',
      'text/html': 'html',
      'application/json': 'json',
      'application/zip': 'zip',
      'application/x-rar-compressed': 'rar',
      'audio/mpeg': 'mp3',
      'audio/wav': 'wav',
      'video/mp4': 'mp4',
      'video/x-msvideo': 'avi',
    };
    return mimeTypes[mimeType] || 'unknown'; 
  }
  avaiablePreview(mineType) {
    return ['jpg', 'gif', 'png', 'pdf', 'jpeg', 'txt'].includes(this.getFileExtension(mineType));
  }

  async downloadFile(document: any) {
    this.processLoading = true;
    try {
      const response = await this.userService.getDigitalFile(document.reference, document.contentType, document.name, document.uuid).toPromise();

      const blob = new Blob([response], { type: 'application/octet-stream' });
      const url = window.URL.createObjectURL(blob);
  
      const a = this.renderer.createElement('a');
      this.renderer.setAttribute(a, 'href', url);

      let ext =  this.getFileExtension(document.contentType);
      
      this.renderer.setAttribute(a, 'download', `${document.name}.${ext}`); 
      a.click();
  
      // Bellekteki URL referansını kaldır
      window.URL.revokeObjectURL(url);
  
      this.processLoading = false;
    } catch {
      this.processLoading = false;
    }
  
  }

  downloadInvoice(document: any) {
  
    const blob = new Blob([  this._base64ToArrayBuffer(document.invoice)], { type: 'application/octet-stream' });
    const url = window.URL.createObjectURL(blob);

    const a = this.renderer.createElement('a');
    this.renderer.setAttribute(a, 'href', url);

    let ext =  this.getFileExtension(document.contentType);
    
    this.renderer.setAttribute(a, 'download', `${document.reference}.pdf`); 
    a.click();

    // Bellekteki URL referansını kaldır
    window.URL.revokeObjectURL(url);
  }


  _base64ToArrayBuffer(base64) {
    var binary_string = window.atob(base64);
    var len = binary_string.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
  }

  clearbase64STR(dataURI) {
    const BASE64_MARKER = ';base64,';
    const base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
    return dataURI.substring(base64Index);
  }

  toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });

  getGrandAmountTotal(items): number {
    return items.reduce((sum, item) => sum + item[this.selectedCurrency], 0);
  }

}
