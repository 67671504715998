import { Component, ViewEncapsulation, OnInit, Injector,  OnDestroy} from '@angular/core';
import { AirSearchService } from 'src/app/services/air/air-search.service';
import { SeaSearchService } from 'src/app/services/sea/sea-search.service';
import { ActivatedRoute ,Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { BaseFormComponentComponent } from 'src/app/shared/base-form-component/base-form-component.component';
import { AirModel } from 'src/app/models/air/air-model';
import { ListService } from 'src/app/services/list/list.service';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { SelectBoxItemService } from 'src/app/services/select-box-item.service';
import { SearchDetailDialog } from 'src/app/components/search-detail-dialog/search-detail-dialog.component';
import { changeDueSub } from 'src/app/components/due-component/due-component.component';
import { TrackingeComponent } from '../tracking/tracking/tracking.component';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
  encapsulation: ViewEncapsulation.None
})


export class ListComponent extends BaseFormComponentComponent implements OnInit, OnDestroy {

  // @ViewChild('container', { read: ViewContainerRef }) container: ViewContainerRef;
  stickyPanel: HTMLElement;
  showDialog: boolean = false;
  dialogHeader: string = "Quote No";
  page: number = 0;
  totalCount: any = 0;
  optionButtonsReady: any[];
  optionButtons: any[] = [
    {label: 'airMenu', value: 'AIR'},
    {label: 'seaMenu', value: 'SEA'},
    {label: 'landMenu', value: 'TRUCK', inactive: true}
  ];
  trackingDialogRef: DynamicDialogRef;

  menuItems: MenuItem[] = [
    {
      label:'View',
      icon:'pi pi-fw pi-eye',
      command: () => this.view(this.selectedResultItem)
   }
  ]

  isLoading: boolean = true;
  selectedListOpiton: string = "AIR";
  searchResult: any[] = [];
  selectedResultItem: any;
  airModel: AirModel;
  searchDetailDialogRef: DynamicDialogRef;
  activeOperationInRoute: string = "";
  first = 0;

  constructor(protected injector: Injector, private airService: AirSearchService, private selectBoxItemService: SelectBoxItemService, private listService: ListService, public activatedRoute: ActivatedRoute, private seaService: SeaSearchService, protected router: Router) {
    super(injector);
  }



  async ngOnInit() {

    
    for (const e of this.optionButtons) {
        e.label = await this.translation.get(e.label).toPromise();
    }
    this.optionButtonsReady = this.optionButtons;

      //get selectbox items
    await this.selectBoxItemService.getItemsAndSaveLocalStorage("marketShipmentStatus");
    await this.selectBoxItemService.getItemsAndSaveLocalStorage("marketOfferSubStatus");


    this.activeOperationInRoute = this.activatedRoute.snapshot.queryParams.operation;

    this.loadList(this.activeOperationInRoute);

    // url değişirse listeyi çağır.
    this.activatedRoute.url.subscribe((s) => {

      if(this.activatedRoute.snapshot.queryParams.operation != this.activeOperationInRoute) {
        this.activeOperationInRoute = this.activatedRoute.snapshot.queryParams.operation;
        this.loadList(this.activatedRoute.snapshot.queryParams.operation);
      } else {
        this.loadList(this.activatedRoute.snapshot.queryParams.operation);
      }


    })

    // Query params değişirse listeyi çağır.
    this.activatedRoute.queryParams.subscribe(p => {

      if(p.operation != this.activeOperationInRoute) {
        this.loadList(p.operation)
        this.activeOperationInRoute = p.operation;
      }

      this.activeOperationInRoute = null;

    });



    changeDueSub.subscribe(s => {
      if(this.searchResult) { this.onChangeOption(this.selectedListOpiton); }
    })

  }

  ngOnDestroy(): void {
    if(changeDueSub) changeDueSub.unsubscribe();
  }

  loadList(operation) {
    if(operation == 'sea') {
      this.onChangeOption("SEA")
    }
    if(operation == 'air') {
      this.onChangeOption("AIR")
    }
    if(operation == 'truck') {
      this.onChangeOption("TRUCK")
    }


  }
  onRowSelect(event) {
    this.selectedResultItem = event;

  }


  async view(item) {
    try{
        this.toggleLoadingProgress();

        console.log(item, "item")

        if (this.selectedListOpiton === 'AIR'){
            this.searchDetailDialogRef = await this.dialogService.open(SearchDetailDialog,{ header: null, showHeader: false, modal: true, width: '90vw', height:'100vh', closable: false, data: {operation: 'AIR', id: item.id} });
        } else if (this.selectedListOpiton === 'SEA'){

          if(['OP', 'DP', 'CL', 'OC'].includes(item.status)) {
            this.showTracking(item);
          } else {
            this.showDetailDialog(item, "SEA");
          }

        }
      this.toggleLoadingProgress();
    } catch {
      this.toggleLoadingProgress();
    }
  }

  async showTracking(item) {
    this.trackingDialogRef = this.dialogService.open(TrackingeComponent, {  header: item.shipmentNo,  width: '100%',  height: '100vh', contentStyle: {"overflow": "auto"}, modal:true,  closable: true,  data: {shipmentNo: item.shipmentNo} });
  }

  
  async showDetailDialog(item, operation) {
    this.searchDetailDialogRef = await this.dialogService.open(SearchDetailDialog,{ header: null, showHeader: false, modal: true, width: '90vw', height:'100vh', closable: false, data: {operation: operation, id: item.id} });
  }




  async getSegment(airModel: AirModel) {
    if(airModel.arrivalPort && airModel.departurePort) {
      return await this.airService.segment(airModel.departurePort.id, airModel.arrivalPort.id).toPromise();
    }
    return null;
  }


  async getAirList(page?: number) {
    try {
      this.isLoading = true;
      switch(this.activatedRoute.snapshot.params.type) {

        case 'notReservedList':
           await this.listService.getList(this.activatedRoute.snapshot.params.type, 'air', page).subscribe(res =>{
              this.totalCount = res.headers.get('Total-Elements');
              this.searchResult = res.body;
              this.isLoading = false;
          })
          break;
        case 'cancelled':
            await this.listService.getList(this.activatedRoute.snapshot.params.type, 'air', page).subscribe(res =>{
                this.totalCount = res.headers.get('Total-Elements');
                this.searchResult = res.body;
                this.isLoading = false;
            });
            break;
        case 'reservation':
            await this.listService.getList(this.activatedRoute.snapshot.params.type, 'air', page).subscribe(res =>{
                this.totalCount = res.headers.get('Total-Elements');
                this.searchResult = res.body;
                this.isLoading = false;
            });
            break;
        case 'inProgressOperation':
            await this.listService.getList(this.activatedRoute.snapshot.params.type, 'air', page).subscribe(res =>{
                this.totalCount = res.headers.get('Total-Elements');
                this.searchResult = res.body;
                this.isLoading = false;
            });
            break;
        case 'completedOperations':
            await this.listService.getList(this.activatedRoute.snapshot.params.type, 'air', page).subscribe(res =>{
                this.totalCount = res.headers.get('Total-Elements');
                this.searchResult = res.body;
                this.isLoading = false;
            });
            break;
        default:
          this.isLoading = true;
          return;
      }
    } catch {
      this.isLoading = false;
    }


  }


  async getSeaList(page?: number) {
    try {
      this.isLoading = true;
      switch(this.activatedRoute.snapshot.params.type) {

        case 'notReservedList':
            await this.listService.getList(this.activatedRoute.snapshot.params.type, 'sea', page).subscribe(res =>{
              this.totalCount = res.headers.get('Total-Elements');
              this.searchResult = res.body;
              this.isLoading = false;
          });
          break;
        case 'cancelled':
            await this.listService.getList(this.activatedRoute.snapshot.params.type, 'sea', page).subscribe(res =>{
                this.totalCount = res.headers.get('Total-Elements');
                this.searchResult = res.body;
                this.isLoading = false;
            });
            break;
        case 'reservation':
            await this.listService.getList(this.activatedRoute.snapshot.params.type, 'sea', page).subscribe(res =>{
                this.totalCount = res.headers.get('Total-Elements');
                this.searchResult = res.body;
                this.isLoading = false;
            });
            break;
        case 'inProgressOperation':
              await this.listService.getList(this.activatedRoute.snapshot.params.type, 'sea', page).subscribe(res =>{
                  this.totalCount = res.headers.get('Total-Elements');
                  this.searchResult = res.body;
                  this.isLoading = false;
              });
              break;
        case 'completedOperations':
              await this.listService.getList(this.activatedRoute.snapshot.params.type, 'sea', page).subscribe(res =>{
                  this.totalCount = res.headers.get('Total-Elements');
                  this.searchResult = res.body;
                  this.isLoading = false;
              });
              break;
        default:
          this.isLoading = true;
          return;
      }
    } catch {
      this.isLoading = false;
    }


  }

 async onChangeOption(event) {
    this.first = 0;
    this.selectedListOpiton = event.value || event;
    this.searchResult = [];
    switch(this.selectedListOpiton) {
      case 'AIR':
        await this.getAirList(0);
        break;
      case 'SEA':
        await this.getSeaList(0);
        break;
      default:
        this.isLoading = false;
        return;
    }

  }

  redirectHome() {
    this.router.navigateByUrl('/home');
  }

    onPage(event: any) {
      if (this.selectedListOpiton === 'AIR'){
          this.getAirList(event.first / 20);
      }else if (this.selectedListOpiton === 'SEA'){
          this.getSeaList(event.first / 20);
      }
    }

  // async showAirPriceInfo() {
  //   this.priceInfoDialogRef = this.dialogService.open(AirPriceDetailDialogComponent, { header: null, showHeader: false, modal:true,  width: '800px', height: 'auto', closable: false,  data: {product: this.airModel.selectedProduct, model: this.airModel}});
  // }

}
