import { AfterViewInit, Component, ElementRef, Input, Output, QueryList, ViewChildren, ViewEncapsulation } from '@angular/core';
import { SeaModel } from 'src/app/models/sea/sea-model';
import { EventEmitter } from '@angular/core';
import { MarketSeaProductsModel } from 'src/app/models/sea/market-sea-products.model';
import { TranslateService } from '@ngx-translate/core';
import { AnimationOptions } from 'ngx-lottie';

export interface ReservationData {
  selectedProduct: MarketSeaProductsModel | null;
  marketProductsDefId: number | null;
  productId: number | null;
}


@Component({
  selector: 'app-sea-card',
  templateUrl: './sea-card.component.html',
  styleUrls: ['./sea-card.component.scss'],
})


export class SeaCardComponent implements AfterViewInit{

  @Input() model: SeaModel = null;
  @Input() loading: boolean = false;
  @Input() alternativeSegmentStartIndex: number = null;
  
  @Output() makeReservation: EventEmitter<ReservationData> = new EventEmitter<ReservationData>();
  @Output() priceInfo: EventEmitter<MarketSeaProductsModel> = new EventEmitter<MarketSeaProductsModel>();
  @Output() frontTariffNotFound: EventEmitter<any> = new EventEmitter<any>();
  @Output() endTariffNotFound: EventEmitter<any> = new EventEmitter<any>();
  @Output() frontEndTariffNotFound: EventEmitter<any> = new EventEmitter<any>();

  loaderOptions: AnimationOptions = {path: '/assets/lottie/load-price.json'};
  laoderStyles: Partial<CSSStyleDeclaration> = {width: '30px',margin: '0 auto',};


  

  @ViewChildren('details') detailsElements: QueryList<ElementRef>;

  constructor(public translation: TranslateService) {

  }

  ngAfterViewInit() {
    // ViewChildren burada güvenli bir şekilde kullanılabilir
  }
  

  toggleDetails(index: number): void {
    const detailsElement = this.detailsElements.toArray()[index].nativeElement;
    const isExpanded = detailsElement.classList.contains('expanded');

    if (isExpanded) {
      detailsElement.style.maxHeight = '0';
      detailsElement.classList.remove('expanded');
    } else {
      detailsElement.style.maxHeight = detailsElement.scrollHeight + 'px';
      detailsElement.classList.add('expanded');
    }
  }

  _makeReservation(selectedProduct: MarketSeaProductsModel = null, marketProductsDefId = null, productId = null) {
    this.makeReservation.emit({selectedProduct,marketProductsDefId,productId})
  }
  _priceInfo(product: MarketSeaProductsModel) {
    this.priceInfo.emit(product)
  }
  _frontTariffNotFound() {
    this.frontTariffNotFound.emit(null);
  }
  _endTariffNotFound() {
    this.endTariffNotFound.emit(null);
  }
  _frontEndTariffNotFound() {
    this.frontEndTariffNotFound.emit(null);
  }
}
