import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { OperationInfo } from 'src/app/models/upload-operation/operation-info';
import { environment } from 'src/environments/environment';
import { BaseService } from '../base.service';
import { catchError, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class OperationInfoService extends BaseService<any> {
  url = environment.baseUrl + '/documentRequest';
  constructor(private http: HttpClient) {
    super("operationInfo", http)
  }

  getOperationInfo(uuid1: string, uuid2: string): Observable<OperationInfo> {
    const url = this.url + `/operations/${uuid1}/${uuid2}`;
    return this.httpClient.get<OperationInfo>(url, { withCredentials: false, headers: this.httpHeaders() })
      .pipe(
        tap(),
        catchError(this.handleError)
      )
  }

  getDocumentById(id: string): Observable<Blob> {
    return this.http.get(this.url + `/${id}`, { responseType: 'blob' });
  }

  uploadFile(uuid1: string, uuid2: string, docTypeId: number, file: File): Observable<any> {
    const formData = new FormData();
    formData.append('uuid1', uuid1);
    formData.append('uuid2', uuid2);
    formData.append('docTypeId', docTypeId.toString());
    formData.append('file', file);

    return this.http.post(this.url + `/operations/${uuid1}/${uuid2}/documents/${docTypeId}`, formData, { responseType: 'text' });
  }

  deleteFile(fileName: string, uuid1: string, uuid2: string, docTypeId: number): Observable<any> {
    return this.http.delete(this.url + `/documents/${fileName}/${uuid1}/${uuid2}/${docTypeId}`, { responseType: 'text' });
  }

  downloadFile(fileName: string): void {
    this.http
      .get(`${this.url}/download/${fileName}`, { responseType: 'blob' })
      .subscribe((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(url);
      });
  }

  approve(lang: string, uuid1: string, uuid2: string): Observable<string> {
    const url = `${this.url}/approve/${lang}/${uuid1}/${uuid2}`;
    return this.http.get(url, { responseType: 'text' });
  }
}